import React from "react"
import { Container, Row, Col, NavLink } from "reactstrap"
import { Link } from "react-router-dom";
import whitepaper from "../../../../assets/docs/BqETH-White-Paper-2.0.pdf";
import lightpaper from "../../../../assets/docs/BqETH-Litepaper-2.0.pdf";

//Import Components
import FooterLink from "./footer-link"

const BqETHFooter = () => {
  const footerLinks = [
    {
      title: "Company",
      links: [
        { title: "About Us", link: "#about" },
        { title: "Features", link: "#features" },
        { title: "Team", link: "/team" },
        { title: "Litepaper", link: lightpaper },
        { title: "Whitepaper", link: whitepaper },
        { title: "Technology", link: "/learn" },
        
      ],
    },
    {
      title: "Resources",
      links: [
        { title: "California Consumer Privacy Act", link: "/ccpa" },
        { title: "Privacy Policy", link: "/privacy" },
        { title: "Terms of Service", link: "/tos" },
        { title: "End User License Agreement", link: "/eula" },
        { title: "FAQ", link: "/faqs" },
        { title: "Contact Us", link: "/contact" },
      ],
    },
  ]

  return (
    <React.Fragment>
      <footer className="landing-footer">
        <Container>
          <Row>
            {footerLinks.map((footerLink, key) => (
              <Col lg="3" sm="6" key={key}>
                <div className="mb-4 mb-lg-0">
                  <h5 className="mb-3 footer-list-title">{footerLink.title}</h5>
                  <ul className="list-unstyled footer-list-menu">
                    {footerLink.links.map((Flink, key) => (
                      <li key={key}>
                        <NavLink href={Flink.link}>{Flink.title}</NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            ))}
          </Row>

          <hr className="footer-border my-5" />

          <FooterLink />
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default BqETHFooter
