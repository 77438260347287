import { createSlice } from "@reduxjs/toolkit";

import { 
    getBqETHDataThunk, 
    newBqETHUserThunk, 
    visitBqETHUserThunk, 
    getBqETHFAQThunk, 
    // getBqETHNextTopicThunk, 
    getBqETHLastTopicThunk,
    visitBqETHTopicThunk, 
    getBqETHNextTopicsFromTopicThunk, 
    getBqETHTopicThunk,
    getBqETHQuizThunk,
    getBqETHPrevTopicFromTopicThunk,
    getBqETHTopicListThunk,
    getBqETHUserQuizThunk,
    getBqETHUserReadyThunk,
    completeFormThunk,
    getBqETHPartnerReadyThunk,
    getBqETHPartnerQuizThunk,
    findMeThunk,
    getBqETHWebinarsThunk,
    setBqETHPartnerActiveThunk, 
} from "./thunk";

export const initialState = {
    users: [],
    error: {}
};

const BqETHSlice = createSlice({
    name: 'BqETHSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getBqETHDataThunk.fulfilled, (state: any, action: any) => {
            state.users = action.payload;
        });

        builder.addCase(getBqETHDataThunk.rejected, (state: any, action: any) => {
            state.error = action.error || null;
        });

        builder.addCase(newBqETHUserThunk.fulfilled, (state: any, action: any) => {
        });

        builder.addCase(newBqETHUserThunk.rejected, (state: any, action: any) => {
        });

        builder.addCase(visitBqETHUserThunk.fulfilled, (state: any, action: any) => {
        });

        builder.addCase(visitBqETHUserThunk.rejected, (state: any, action: any) => {
        });

        builder.addCase(getBqETHFAQThunk.fulfilled, (state: any, action: any) => {
            state.faqs = action.payload;
        });

        builder.addCase(getBqETHFAQThunk.rejected, (state: any, action: any) => {
            state.error = action.error || null;
        });

        builder.addCase(getBqETHTopicListThunk.fulfilled, (state: any, action: any) => {
            state.course = action.payload;
        });

        builder.addCase(getBqETHTopicListThunk.rejected, (state: any, action: any) => {
            state.error = action.error || null;
        });

        // builder.addCase(getBqETHNextTopicThunk.fulfilled, (state: any, action: any) => {
        //     // If action.payload is undefined, the user was not found
        //     if (action.payload && action.payload[0]) {
        //         // Clean up some fields, if the API didn't
        //         delete action.payload[0]._table;
        //         delete action.payload[0]._rawJson
        //         state.topics = action.payload;
        //     }
        // });

        // builder.addCase(getBqETHNextTopicThunk.rejected, (state: any, action: any) => {
        //     state.error = action.error || null;
        // });

        builder.addCase(getBqETHLastTopicThunk.fulfilled, (state: any, action: any) => {
            // If action.payload is undefined, the user was not found
            if (action.payload && action.payload[0]) {
                // Clean up some fields, if the API didn't
                delete action.payload[0]._table;
                delete action.payload[0]._rawJson
                state.topics = action.payload[0];
            }
        });

        builder.addCase(getBqETHLastTopicThunk.rejected, (state: any, action: any) => {
            state.error = action.error || null;
        });

        builder.addCase(visitBqETHTopicThunk.fulfilled, (state: any, action: any) => {
        });

        builder.addCase(visitBqETHTopicThunk.rejected, (state: any, action: any) => {
            state.error = action.error || null;
        });

        builder.addCase(completeFormThunk.fulfilled, (state: any, action: any) => {
        });

        builder.addCase(completeFormThunk.rejected, (state: any, action: any) => {
            state.error = action.error || null;
        });

        builder.addCase(getBqETHNextTopicsFromTopicThunk.fulfilled, (state: any, action: any) => {
            if (action.payload) {
                // Clean up some fields, if the API didn't
                delete action.payload[0]._table;
                delete action.payload[0]._rawJson
                state.next_topics = action.payload;
            }
        });

        builder.addCase(getBqETHNextTopicsFromTopicThunk.rejected, (state: any, action: any) => {
            state.error = action.error || null;
        });

        builder.addCase(getBqETHPrevTopicFromTopicThunk.fulfilled, (state: any, action: any) => {
            if (action.payload) {
                // Clean up some fields, if the API didn't
                delete action.payload._table;
                delete action.payload._rawJson;
                if (action.payload.fields)
                    state.prev_topic = action.payload;
            }
        });

        builder.addCase(getBqETHPrevTopicFromTopicThunk.rejected, (state: any, action: any) => {
            state.error = action.error || null;
        });

        builder.addCase(getBqETHTopicThunk.fulfilled, (state: any, action: any) => {
            if (action.payload) {
                // Clean up some fields, if the API didn't
                delete action.payload._table;
                delete action.payload._rawJson;
                state.topics = action.payload;
            }
        });

        builder.addCase(getBqETHTopicThunk.rejected, (state: any, action: any) => {
            state.error = action.error || null;
        });

        builder.addCase(getBqETHQuizThunk.fulfilled, (state: any, action: any) => {
            // If action.payload is undefined, the user was not found
            if (action.payload) {
                // Clean up some fields, if the API didn't
                delete action.payload._table;
                delete action.payload._rawJson
                state.quiz = action.payload;
            }
        });

        builder.addCase(getBqETHQuizThunk.rejected, (state: any, action: any) => {
            state.error = action.error || null;
        });

        builder.addCase(getBqETHUserQuizThunk.fulfilled, (state: any, action: any) => {
            // If action.payload is undefined, the user was not found
            if (action.payload && action.payload.user_quiz !== undefined) {
                state.user_quiz_pass = action.payload.user_quiz;
                state.user_quiz_topic = action.payload.topic_id;
            }
        });

        builder.addCase(getBqETHUserQuizThunk.rejected, (state: any, action: any) => {
            state.error = action.error || null;
        });

        builder.addCase(getBqETHPartnerQuizThunk.fulfilled, (state: any, action: any) => {
            // If action.payload is undefined, the user was not found
            if (action.payload && action.payload.user_quiz !== undefined) {
                state.partner_quiz_answered = action.payload.user_quiz;
                state.partner_quiz_topic = action.payload.topic_id;
            }
        });

        builder.addCase(getBqETHPartnerQuizThunk.rejected, (state: any, action: any) => {
            state.error = action.error || null;
        });

        builder.addCase(getBqETHUserReadyThunk.fulfilled, (state: any, action: any) => {
            // If action.payload is undefined, the user was not found
            if (action.payload && action.payload.user_ready !== undefined) {
                state.user_ready = action.payload.user_ready;
            }
        });

        builder.addCase(getBqETHUserReadyThunk.rejected, (state: any, action: any) => {
            state.error = action.error || null;
        });

        builder.addCase(getBqETHPartnerReadyThunk.fulfilled, (state: any, action: any) => {
            // If action.payload is undefined, the user was not found
            if (action.payload && action.payload.partner_ready !== undefined) {
                // state.partner_score = action.payload.score;
                state.partner_ready = action.payload.partner_ready;
                state.answered = action.payload.answered
            }
        });

        builder.addCase(getBqETHPartnerReadyThunk.rejected, (state: any, action: any) => {
            state.error = action.error || null;
        });

        builder.addCase(setBqETHPartnerActiveThunk.fulfilled, (state: any, action: any) => {
        });

        builder.addCase(setBqETHPartnerActiveThunk.rejected, (state: any, action: any) => {
            state.error = action.error || null;
        });

        builder.addCase(findMeThunk.fulfilled, (state: any, action: any) => {
            // If action.payload is undefined, the user was not found
            if (action.payload && action.payload.uuidCookie !== undefined) {
                state.uuidCookie = action.payload.uuidCookie;
                state.acceptedToS = action.payload.acceptedToS;
            }
            else {
                state.error = { message: 'parameter invalid' } || null;
                state.uuidCookie = 'not found';
            }
        });

        builder.addCase('clearLogin', (state: any) => {
            state.uuidCookie = undefined;
            state.acceptedToS = undefined;
        });

        builder.addCase(findMeThunk.rejected, (state: any, action: any) => {
            state.error = { message: 'parameter invalid' } || null;
            state.uuidCookie = 'not found';
        });

        builder.addCase(getBqETHWebinarsThunk.fulfilled, (state: any, action: any) => {
            if (action.payload && action.payload.data !== "") {
                state.webinars = action.payload;
            }
        });

        builder.addCase(getBqETHWebinarsThunk.rejected, (state: any, action: any) => {
            state.error = action.error || null;
        });
    }
})

export default BqETHSlice.reducer;