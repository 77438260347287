import React, { useEffect, useState } from "react"
import { Container, Row, Col, CardBody, Card } from "reactstrap";

import StaticNavbar from "../Navbar/StaticNavBar";
import BqETHFooter from "../Footer/footer";
import { useDispatch, useSelector } from "react-redux";
import { getBqETHWebinarsThunk } from "slices/thunk";
import { Link } from "react-router-dom";

const Webinars = () => {

 //meta title
document.title = "Contact Us | BqETH";

  // State for current active Tab 
  const [currentActiveTab, setCurrentActiveTab] = useState('Webinars'); 

  // Toggle active state for Tab 
  const toggle = tab => { 
      if (currentActiveTab !== tab) setCurrentActiveTab(tab); 
  } 

  const dispatch = useDispatch<any>();

  const webinars = useSelector((state: any) => state.bqeth.webinars);
  const [local_webinars, set_local_webinars] = useState(webinars);
  useEffect(() => {
    set_local_webinars(webinars)
  },[webinars]);

  /* get data */
  useEffect(() => {
    dispatch(getBqETHWebinarsThunk());
  }, [dispatch]);

  return (
    <React.Fragment>
      <StaticNavbar imglight={false}/>
      <section className="section" id="contact">
        <Container fluid >
              <Row className="justify-content-center">
                  <Col lg="12">
                    <div className="text-center mb-5">
                      <h4>Upcoming Webinars</h4>
                    </div>
                  </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <div className="vertical-nav">
                    {local_webinars ? local_webinars.map((web, index) => (
                        <Card>
                          <CardBody>
                          <div className="mb-3">
                            <i className="mdi mdi-bitcoin h2 text-bqeth-teal" />
                          </div>
                          <p className="text-muted mb-0">
                            {web.startTime}
                          </p>
                          <h5>{web.topic}</h5>
                          <p className="text-muted mb-0">
                            {web.agenda}
                          </p>
                        </CardBody>
                        <div className="card-footer bg-transparent border-top text-center">
                          <Link to={web.registrationLink} className="text-primary">
                            Register
                          </Link>
                        </div>
                          </Card>                              
                     )):
                     <div>There are no webinars currently scheduled.</div>} 
                    </div>
                  </Col>
                </Row>
            </Container>
      </section>
      <BqETHFooter />
    </React.Fragment>
  )
}
export default Webinars
