import React, { useEffect, useState } from "react";
import { createSelector } from 'reselect';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"

//redux
import { useSelector, useDispatch } from "react-redux";
import { TopicItem, getBqETHFAQThunk } from "slices/bqeth/thunk";
import FAQccordeon from "./FAQccordeon";
import StaticNavbar from "../Navbar/StaticNavBar";

// actions

const BqETHFAQ = (props: any) => {
  
  const [activeTab, setactiveTab] = useState("1")

  const dispatch = useDispatch<any>();

  const selectProperties = createSelector(
    (state: any) => state.bqeth.faqs,
    (faqs) => faqs
  );

  const faqs = useSelector(selectProperties);

  type BucketedItems = { [key: string]: TopicItem[] };
  const [bucketedFAQs, setBucketedFAQs] = useState<BucketedItems>();

  /* get data */
  useEffect(() => {
    dispatch(getBqETHFAQThunk());
  }, [dispatch]);

  useEffect(() => {
    // Once faqs have been received from the API, we bucket them by their filters
    function bucketItemsByFiltering(itemlist: TopicItem[]): BucketedItems {
      const bucketedItems: BucketedItems = {
        'General Question': [],
      };
    
      function topic_order( t1: TopicItem, t2: TopicItem ) {
        if ( t1.fields.order == undefined || t2.fields.order == undefined) {
          if ( t2.fields.order != undefined ){
            return -1;
          }
          if ( t1.fields.order != undefined  ){
            return 1;
          }
          return 0;
        }
        if ( t1.fields.order < t2.fields.order ){
          return -1;
        }
        if ( t1.fields.order > t2.fields.order  ){
          return 1;
        }
        return 0;
      }
      var sortedItems = [...itemlist].toSorted( topic_order);

      for(var i=0; i<sortedItems.length; i++) {
        var item: TopicItem = sortedItems[i];
        let filters: string[];

        if (item.fields.Filtering) {
          filters = Array.isArray(item.fields.Filtering) ? item.fields.Filtering : [item.fields.Filtering];
        } else {
          filters = [];
        }
    
        // Remove FAQ as a filter
        if (filters.length === 0 || (filters.length === 1 && filters[0] === 'FAQ')) {
          // Assign to 'General Question' bucket if Filtering is empty or has only 'FAQ'
          bucketedItems['General Question'].push(item);
        } 
        else if (filters.length === 2 && filters.includes('Course') && filters.includes('FAQ')) {
          bucketedItems['General Question'].push(item);
        }
        else {
          // Assign to respective buckets based on Filtering values
          for (var j=0; j<filters.length; j++) {
            var filter = filters[j];
              if (!bucketedItems[filter]) {
                bucketedItems[filter] = [];
              }
              bucketedItems[filter].push(item);
          }
        }
      };
      // console.log(bucketedItems);

      // Remove the FAQ and Course Keys from the FAQs
      delete bucketedItems['Course'];
      delete bucketedItems['FAQ'];
      delete bucketedItems['AdvisorQuiz'];
      delete bucketedItems['Advisor'];
    
      return bucketedItems;
    }

    if (faqs) {
      setBucketedFAQs(bucketItemsByFiltering(faqs));
    }
  }, [faqs]);

  return (
    <React.Fragment>
      <StaticNavbar imglight={false}/>
      <section className="section" id="faqs">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="small-title">FAQ</div>
                <h4>Frequently Asked Questions</h4>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="vertical-nav">
                <Row>
                  <Col lg="2" sm="4">
                    <Nav pills className="flex-column">
                     {/* Create the Bucket Navigations Links  */}
                    {bucketedFAQs? Object.keys(bucketedFAQs).map((filter, index) => (
                      <NavLink
                        key={index}
                        className={classnames({ active: activeTab === `${index + 1}` })}
                        onClick={() => { setactiveTab(`${index + 1}`);}}>
                        <i className="bx bx-help-circle nav-icon d-block mb-2" />
                        <p className="font-weight-bold mb-0">{filter}</p>
                      </NavLink>
                    )): null }
                    </Nav>
                  </Col>
                  <Col lg="10" sm="8">
                    <Card>
                      <CardBody>
                        <TabContent activeTab={activeTab}>
                          {/* Create the Bucket Tabs */}
                        {bucketedFAQs? Object.values(bucketedFAQs).map((bucket, index) => (
                          <TabPane key={index} tabId={`${index + 1}`}>
                            <h4 className="card-title mb-4">{Object.keys(bucketedFAQs)[index]}</h4>
                            {/* Create the FAQ Topic Entries  */}
                            <FAQccordeon entries={bucket.map(item => ({ 
                              topic: item.fields.topic_id, 
                              order: item.fields.order,
                              question: item.fields.FullTitle, 
                              answer: item.fields.Component 
                              }))} />
                          </TabPane>
                        )):null}
                        </TabContent>
                      </CardBody>
                    </Card>
                  </Col>

                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default BqETHFAQ;