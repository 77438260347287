import React, { useEffect, useState } from "react"
import { Container, Row, Col, Button, UncontrolledTooltip, Card, 
  CardBody, Collapse, Spinner } from "reactstrap";

//Import Breadcrumb
import StaticNavbar from "../Navbar/StaticNavBar";

//redux
import { useSelector, useDispatch } from "react-redux";
import { TopicItem, 
  getBqETHLastTopicThunk, 
  getBqETHNextTopicsFromTopicThunk, 
  getBqETHPrevTopicFromTopicThunk, 
  getBqETHTopicListThunk, 
  getBqETHTopicThunk, 
  getBqETHUserQuizThunk, 
  getBqETHUserReadyThunk, 
  visitBqETHTopicThunk } from "slices/bqeth/thunk";
import { createSelector } from "reselect";
import { useCookies } from "react-cookie";
import { QuizItem, getBqETHQuizThunk } from "slices/thunk";
import DOMPurify from 'dompurify';
import { Link } from "react-router-dom";
import ImageComponent from "../RenderComponents/ImageComponent";
import { DAPP } from "helpers/url_helper";


type BulletListItem = {
  key: string;
  value: string;
};

type QuestionType = {
  question: string;
  bulletList: BulletListItem[];
};

function parseQuestionAndBulletList(inputString: string): QuestionType {
  const result: QuestionType = {
    question: '',
    bulletList: []
  };

  const regex = /^(.*?):\s*(.*)$/;
  const questionMatch = inputString.match(regex);

  if (questionMatch) {
    result.question = questionMatch[1].trim();
    const bulletListString = questionMatch[2];

    // Matches [digit]  Text  
    const bulletRegex = /\[(\d+)\]\s*(.*?)(?=(?:\[\d+\]|\s*\.\s*|$))/g;
    let bulletMatch;

    while ((bulletMatch = bulletRegex.exec(bulletListString)) !== null) {
      const [, key, value] = bulletMatch;
      result.bulletList.push({ key, value: value.trim() });
    }
  }

  return result;
}

// This is what happens in this page:
// 1. We dispatch getBqETHLastTopicThunk when we detect the user cookie (set by the App level) in the 1st useEffect
// 2. This will return from the thunk and pass throught the reducer, setting the state.topics , which triggers the selector below
// 3. After the selectCurrentTopic selector has run, cur_t will have changed, triggering the 2nd useEffect
// 4. The 2nd useEffect  sets the 'currentTopic' variable and dispatches the getBqETHNextTopicsFromTopicThunk
// 5. As a result of 'currentTopic' having a new value, the 3rd useEffect dispatches getBqETHQuizThunk if there is a Quiz to fetch
// 6. After the selectNextTopics selector has run, nextList will have a new value, and the 4th useEffect will run
// 7. After the selectCurrentQuiz selector has run, qiz will have a new value, and the 5th useEffect will run
// 8. The 5th useEffect takes care of selecting a random question from the quiz and setting question related answer options
const Learn = () => {

  const [cookies, ] = useCookies(['userUuid']);
  document.title = "Learn | BqETH";

  const dispatch = useDispatch<any>();

  // SELECTORS

  const selectCurrentTopic = createSelector(
    (state: any) => state.bqeth.topics,
    (topics) => topics
  );
  const cur_t: TopicItem = useSelector(selectCurrentTopic);

  const selectNextTopics = createSelector(
    (state: any) => state.bqeth.next_topics,
    (topics) => topics
  );
  const nextList = useSelector(selectNextTopics);

  const selectPrevTopic = createSelector(
    (state: any) => state.bqeth.prev_topic,
    (prev_topic) => prev_topic
  );
  const prevTopic: TopicItem = useSelector(selectPrevTopic);

  const selectCurrentQuiz = createSelector(
    (state: any) => state.bqeth.quiz,
    (quiz) => quiz
  );
  const qiz: QuizItem[] = useSelector(selectCurrentQuiz);

  const selectUserQuiz = createSelector(
    (state: any) => state.bqeth.user_quiz,
    (user_quiz) => user_quiz
  );
  const user_q: boolean = useSelector(selectUserQuiz);

  const selectUserReady = createSelector(
    (state: any) => state.bqeth.user_ready,
    (user_ready) => user_ready
  );
  const user_ready: boolean = useSelector(selectUserReady);

  const selectCourseList = createSelector(
    (state: any) => state.bqeth.course,
    (course) => course
  );

  const course = useSelector(selectCourseList);

  // STATE VARIABLES
  
  const [currentTopic, setCurrentTopic] = useState<TopicItem>(  
    { // Some default topic "Loading... "
        id: "rec5qqRODNdSZdiBB",
        fields: {
            "topic_id": 33,
            "order": "1",
            "topic_tree": "record",
            "ShortTitle": "Loading ...",
            "Filtering": ["Course"],
            "Component": "Loading... ",
            "FullTitle": "Loading ...",
            "direction": "next",
            "quiz": "",
            "topic_id_label": "Loading ...",
        },
        direction: "start"
    }
  );  
  const [backList, setBackList] = useState<any[]>([],);        // Back List (Undo style list)
  const [prevBtnTopic, setPrevBtnTopic] = useState<number>();
  const [prevBtnText,  setPrevBtnText ] = useState<string>("");
  const [nextBtnTopic, setNextBtnTopic] = useState<number>();
  const [nextBtnText,  setNextBtnText ] = useState<string>("");
  const [jumpBtnTopics, setJumpBtnTopics] = useState<number[]>([],);
  const [jumpBtnTexts,  setJumpBtnTexts ] = useState<string[]>([""],);
  const [enableNext, setEnableNext] = useState<boolean>(true);
  const [question, setQuestion] = useState<QuestionType>();
  const [questionIdx, setQuestionIdx] = useState<number>(0);
  const [fetchingQuiz, setFetchingQuiz] = useState(false);
  const [quizOpen, setQuizOpen] = useState(false);
  const [userQuiz, setUserQuiz] = useState(false);
  const [userReady, setUserReady] = useState(false);
  const [answered, setAnswered] = useState<boolean>(false);
  const [rightAnswer, setRightAnswer] = useState<boolean>(false);
  const [previousTopic, setPreviousTopic] = useState<TopicItem>(); 
  const [courseList, setCourseList] = useState<TopicItem[]>([],); 

  // USE EFFECTS
  useEffect(() => {
    /* Fetch the Next topic we should show, based on user's last visit */
    if (cookies['userUuid']) {   
      console.log("Detected cookie, fetching appropriate node for the user");
      dispatch(getBqETHLastTopicThunk({ user_hash: cookies['userUuid']}));
      dispatch(getBqETHTopicListThunk());
      dispatch(getBqETHUserReadyThunk({ user_hash: cookies['userUuid']}));
    }
  }, [dispatch, cookies['userUuid']]);

  useEffect(() => {
    /* Fetch the next topic for the current topic */
    if (cur_t) {
      setCurrentTopic(cur_t);
      // console.log("Detected cur_t, fetching next/prev nodes for current topic:", cur_t.fields.topic_id);
      dispatch(getBqETHPrevTopicFromTopicThunk({ topic: cur_t.fields.topic_id}));
      dispatch(getBqETHNextTopicsFromTopicThunk({ topic: cur_t.fields.topic_id}));
    }
  }, [cur_t]);
  
  useEffect(() => {
    /* Fetch the quiz for the current topic if any */
    if (currentTopic.fields.quiz) {
      console.log("Current topic changed. Has a quiz: fetching the quiz");
      dispatch(getBqETHQuizThunk({ quiz: currentTopic.fields.quiz}));
      setFetchingQuiz(true);
    }
    else {
      console.log("Current topic changed. No quiz");
      setQuestion(undefined);
      setEnableNext(true);
      setAnswered(false);
      setFetchingQuiz(true);
    }
  }, [currentTopic]); 

  useEffect(() => {
    /* Interpret the list of next/jump topics and set buttons */
    if (nextList) {

      var hasJump: boolean = false;
      let localJumpTopics: number[] = [];
      let localJumpTexts: string[] = [];
      nextList.forEach(next => {
        if (next.direction === "next") {
          setNextBtnTopic(next.fields.topic_id);
          // console.log("New next topic:",next.fields.topic_id);
          setNextBtnText(next.fields.FullTitle);
        }
        else if (next.direction === "jump") {
          // console.log("New jump topic:",next.fields.topic_id);
          localJumpTopics.push(next.fields.topic_id);
          localJumpTexts.push(next.fields.FullTitle);
          hasJump = true;
        }
      });
      if (!hasJump) {
        setJumpBtnTopics([],);
        setJumpBtnTexts([],);
      }
      else {
        setJumpBtnTopics(localJumpTopics);
        setJumpBtnTexts(localJumpTexts);
      }
    }
  }, [nextList]);  // This is updated by the selector

  useEffect(() => {
    if (prevTopic) {  // Sometimes there's not a parent
      setPreviousTopic(prevTopic);
      // Also, if baclList is empty, use the parent to current topic
      if (backList.length == 0) {
        setBackList([...backList, prevTopic]);
        setPrevBtnText(prevTopic.fields.FullTitle);
        setPrevBtnTopic(prevTopic.fields.topic_id);
      }
    }
  }, [prevTopic]);  // This is updated by the selector

  useEffect(() => {
    console.log("qiz changed.");
     /* Interpret the quiz, select a random question, create answer sets */
    if (qiz && qiz.length > 0) {  // An Array of questions
      // Pick a random question
      const rnd: number = randomInt(0,qiz.length);
      const question: QuestionType = parseQuestionAndBulletList(qiz[rnd].fields.question_content);
      setQuestionIdx(rnd);
      setQuestion(question);
      setAnswered(false);
      console.log("Picked a question. Dispatching the check for user's answer.");
      dispatch(getBqETHUserQuizThunk({ 
        user_hash: cookies['userUuid'], 
        topic: cur_t.fields.topic_id
      }));
      
      // Question based enabled things
      if (qiz[rnd].fields.must) {
        setQuizOpen(qiz[rnd].fields.must) // If question is a must, pre-open the quiz
      }
      setFetchingQuiz(false);
    }
    else {
      console.log("qiz changed to undefined. Resetting answered.");
      setQuestion(undefined);
      setEnableNext(true);
      setAnswered(false);
      setQuizOpen(false)
      setFetchingQuiz(true);
    }
  }, [qiz]); 

  useEffect(() => {
    if (user_q != undefined) {
      console.log("user_q changed to :", user_q);
      setUserQuiz(user_q);
      setEnableNext(user_q);
      setAnswered(user_q)
    }
    else {
      console.log("user_q changed to undefined. Resetting answered.");
      setAnswered(false);
    }
  }, [user_q]); 

  useEffect(() => {
    /* Interpret the quiz, select a random question, create answer sets */
    if (user_ready) {  // An Array of questions
      console.log("user_ready changed:", user_ready);
      setUserReady(user_ready);
    }
    else {
      console.log("user_ready changed back to undefined.");
      setUserReady(false);
    }
  }, [user_ready]); 

  useEffect(() => {
    /* Interpret the list course topics */
    if (course) {
      setCourseList(course);
    }
  }, [course]);  // This is updated by the selector

  // HANDLER FUNCTIONS  
  const getCurrentDate= () => {
    return new Date().toISOString();
  }

  function pushBackList(topic: any) {
    // update the state to the new list
    setBackList([...backList, topic]);
  }

  function popBackList(): any | undefined {
    const item = backList[backList.length-1];
    // update the state to the new list
    setBackList([
      ...backList.slice(0, backList.length-1),
    ])
    return item;
  }

  // We advance to the next topic
  const frwd = () => {
    
    // 1. Record that we visited the topic
    // 2. Stash Previous topic
    // 3. Fetch the jump topic, which trigger fetch of children and updates next and jump topics

    // Create/Update user interaction  (when the user moves off the topic and into the next)
    dispatch(visitBqETHTopicThunk({ 
      user_hash: cookies['userUuid'],     
      date: getCurrentDate(),
      topic: currentTopic.fields.topic_id,
      quiz_good: rightAnswer
    }));
    // console.log("Setting previous topic to: ", currentTopic.fields.topic_id);
    pushBackList(currentTopic);
    setPrevBtnText(currentTopic.fields.FullTitle);
    setPrevBtnTopic(currentTopic.fields.topic_id);
    dispatch(getBqETHTopicThunk({ 
        topic: nextBtnTopic
      }));
    // console.log("Fetching next topic for : ", nextBtnTopic);
  }

  // We jump to a whole new topic
  const jump = (id: number) => {
    dispatch(visitBqETHTopicThunk({ 
      user_hash: cookies['userUuid'],     
      date: getCurrentDate(),
      topic: currentTopic.fields.topic_id
    }));
    // console.log("Setting previous topic to: ", currentTopic.fields.topic_id);
    pushBackList(currentTopic);
    setPrevBtnText(currentTopic.fields.FullTitle);
    setPrevBtnTopic(currentTopic.fields.topic_id);
    dispatch(getBqETHTopicThunk({ 
      topic: id
    }));
  }

  // We jump to the previous topic
  const back = () => {
    dispatch(visitBqETHTopicThunk({ 
      user_hash: cookies['userUuid'],     
      date: getCurrentDate(),
      topic: currentTopic.fields.topic_id
    }));
    console.log("Setting previous topic to: ", currentTopic.fields.topic_id);
    const previous = popBackList();
    if (previous) {
      // This sets the new current element
      setCurrentTopic(previous);
      // This sets the new previous if any
      if (backList.length > 1) {
        // Set the new previous to the head of the backList's values
        setPrevBtnText(backList[backList.length-2].fields.FullTitle);
        setPrevBtnTopic(backList[backList.length-2].fields.topic_id);
      }
      dispatch(getBqETHTopicThunk({ 
        topic: previous.fields.topic_id
      }));
      console.log("Fetching next topic for : ", previous.topic_id);
    }
  }

  // From [min,max[
  const randomInt = (min, max) =>  Math.floor(Math.random() * (max - min)) + min;

  function toggleQuiz() {
    setQuizOpen(!quizOpen)
  }

  const answer = (id: number) => {
    console.log("Capturing Answer : ", id);
    // dispatch(captureAnswer({ answer: id }));
    setEnableNext(true);
    setAnswered(true);
    var correct: boolean = qiz[questionIdx].fields.correct_answer_is == id+1;
    setRightAnswer(correct);
    console.log("Answer is: ", correct);
    dispatch(visitBqETHTopicThunk({ 
      user_hash: cookies['userUuid'],     
      date: getCurrentDate(),
      topic: currentTopic.fields.topic_id,
      quiz_good: rightAnswer
    }));
  }

  const renderCourseList = (courselist: TopicItem[]) => {
    return (
      <tbody style={{ textAlign: 'left' }} >
      {courseList.map((t) => 
      <tr><td>
        {t.fields.ShortTitle.endsWith('?') ? 
          <div>
            <i className={"mdi mdi-check"}/><button className="anchor-button" 
            key={t.fields.topic_id} onClick={() =>  jump(t.fields.topic_id)}
            style={t.fields.topic_id === currentTopic.fields.topic_id ? { fontWeight : "bold" }: { backgroundColor : "inherit" }} 
              > {t.fields.ShortTitle}</button></div>
              :
          <button className="anchor-button" 
          key={t.fields.topic_id} onClick={() =>  jump(t.fields.topic_id)}
          style={t.fields.topic_id === currentTopic.fields.topic_id ? { fontWeight : "bold" }: { backgroundColor : "inherit" }} 
            > {t.fields.ShortTitle}</button>}
      </td></tr>
      )}
    </tbody>
    );
  }

  const TopicComponent = ({ content, onJump }) => {

    const renderContent = () => {
      if (content) {
        const regex = /(\[T\d+T*\])/g;
        const parts = content.split(regex);

        if (parts) {
          return parts.map((part, index) => {
            if (regex.test(part)) {
              const number = part.match(/\d+/)[0];
              const key = 'IT-'+index;
              if (number === "0") {  
                if (part === '[T0]') {
                  // Probe the user ready state in case this answer affects it
                  dispatch(getBqETHUserReadyThunk({ user_hash: cookies['userUuid']}));
                  return (  // [T0] is special: Jump to the app
                      <a href={DAPP+'?test_mode='+!userReady} target="_blank" rel="noreferrer" > Launch App </a>
                  );
                }
                else {
                  return (  // [T0] is special: Jump to the app
                      <a href={DAPP+'?test_mode=true'} target="_blank" rel="noreferrer" > Launch App </a>
                  );
                }
              }
              else {
                return (
                    <button key={key} className="anchor-button" 
                      onClick={() => onJump(Number(number))}>
                      here
                    </button>
                );
              }
            } else {
              const key = 'I-'+index;
              return <ImageComponent key={key} content={part} />
            }
          });
        }
      }
      else {  // Empty topic, return something.
        return <div></div>
      }
    };
  
    return <>{renderContent()}</>;
  };

  return (
    <React.Fragment>
      <StaticNavbar imglight={false}/>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <h4>Learn</h4>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center text-left mb-5">
          <Col lg={2}  >
        {/* Course List */}
              {courseList && courseList.length > 0 ? renderCourseList(courseList): null }
          </Col>
        {/* Current Topic */}
            <Col lg={8}>
                  {currentTopic ? 
                    <Card className="border plan-box " styles={[{ marginTop: 16 }]}>
                      <CardBody className="p-5">
                        {/* Topic Section */}
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <h5>{currentTopic.fields.FullTitle}</h5>
                            {/* <p className="text-muted">{topics[0].fields.FullTitle}</p> */}
                          </div>
                        </div>
                        <TopicComponent content={currentTopic.fields.Component} onJump={jump}/>
                        {fetchingQuiz && qiz && question &&
                        <Spinner color="primary">Loading...</Spinner>
                        }
                        {/* Quiz Section Buttons */}
                        {!fetchingQuiz && qiz && userQuiz && question? 
                        <div >You already answered this question correctly.</div>
                        : null }
                        {!fetchingQuiz && qiz && !userQuiz && question?
                        <div id="gen-ques-accordion" className="accordion custom-accordion">
                          <div className="mb-3">
                            <Link
                              to="#"
                              className="accordion-list"
                              onClick={() => {toggleQuiz()}}
                              style={{ cursor: "pointer" }}
                            >
                              <div>Test your knowledge</div>
                              <i
                                className={
                                  quizOpen
                                    ? "mdi mdi-minus accor-plus-icon"
                                    : "mdi mdi-plus accor-plus-icon"
                                }
                              />
                            </Link>
                            <Collapse isOpen={quizOpen}>
                              {/* Question Section  */}
                              {question && question.question ?
                              <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question.question) }} />
                              : null 
                                }
                              {/* Answer Buttons Section  */}
                              {question && question.bulletList && Object.keys(question.bulletList).length > 0 ? 
                                <div className="justify-content-center text-center mb-5">
                                      {question.bulletList.map(({ key, value }, i) => 
                                      <Button className="btn-blue-flat mb-1 me-5" type="button" 
                                        disabled={answered} key={key} onClick={() =>  answer(i)}> {key}: {value}</Button>
                                      )}
                                </div>
                                    : null 
                                }
                              {answered && rightAnswer ?
                                <TopicComponent content={qiz[questionIdx].fields.correct_answer_text} onJump={jump} />
                              : null }
                              {answered && !rightAnswer ?
                                <TopicComponent content={qiz[questionIdx].fields.incorrect_answer_text} onJump={jump} />
                              : null 
                                }
                            </Collapse>
                          </div>
                        </div>  : null }
                      </CardBody>
                    </Card> : null
                  }
        {/* Navigation Buttons Back + Next */}
              {backList.length > 0 && prevBtnTopic? 
                <UncontrolledTooltip placement="top-start" target="prevBtn">{prevBtnText}</UncontrolledTooltip> : null }
              {nextBtnTopic? 
                <UncontrolledTooltip placement="top-start" target="nextBtn">{nextBtnText}</UncontrolledTooltip> : null }

              {backList.length > 0 && prevBtnTopic? 
                <Button id="prevBtn" type="button" onClick={back} className="btn-slv mb-1 me-5" > Back to: {prevBtnText}</Button>
                      : null }
              {nextBtnTopic? 
                <Button id="nextBtn" type="button" disabled={!enableNext} onClick={frwd} className="btn-blue mb-1 me-5" > Next :  {nextBtnText}</Button>
                      : null }
                                    
        {/* Jump Buttons */}
              {/* {jumpBtnTopic? 
                  <UncontrolledTooltip placement="top-start" target="jumpBtn">{jumpBtnText}</UncontrolledTooltip> : null } */}
              {jumpBtnTopics && jumpBtnTexts && jumpBtnTopics.length > 0 ? 
                  <tbody style={{ textAlign: 'left' }}>
                    Related Topics:
                    {jumpBtnTopics.map((t, i) => 
                    <tr>
                      <button className="anchor-button" 
                          key={t} onClick={() => jump(t)}>{jumpBtnTexts[i]}</button>
                    </tr>
                    )}
                  </tbody>
                  : null 
              }

            </Col>
          </Row>                  
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Learn
