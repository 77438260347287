
//Crypto
import CryptoIcoLanding from "../pages/Crypto/CryptoIcoLanding/index";

//Pages
import Pages404 from "../pages/Utility/pages-404";

//Icons
import IconMaterialdesign from "../pages/Crypto/Icons/IconMaterialdesign";
import IconFontawesome from "../pages/Crypto/Icons/IconFontawesome"

import BqETHPricing from "pages/Crypto/CryptoIcoLanding/Pricing/bqeth-pricing";
import OurTeam from "pages/Crypto/CryptoIcoLanding/Team/our-team";
import Learn from "pages/Crypto/CryptoIcoLanding/Learn/learn";
import BqETHFAQ from "pages/Crypto/CryptoIcoLanding/Faqs/BqETHFaq";
import Contact from "pages/Crypto/CryptoIcoLanding/AboutUs/contact";
import Professionals from "pages/Crypto/CryptoIcoLanding/Professionals/Professionals";
import Privacy from "pages/Crypto/CryptoIcoLanding/AboutUs/Privacy";
import CCPA from "pages/Crypto/CryptoIcoLanding/AboutUs/CCPA";
import ToS from "pages/Crypto/CryptoIcoLanding/AboutUs/ToS";
import Webinars from "pages/Crypto/CryptoIcoLanding/Professionals/Webinars";
import EULA from "pages/Crypto/CryptoIcoLanding/AboutUs/EULA";
import PCA from "pages/Crypto/CryptoIcoLanding/AboutUs/PCA";

const authProtectedRoutes = [

  // Icons
  { path: "/icons-materialdesign", component: <IconMaterialdesign /> },
  { path: "/icons-fontawesome", component: <IconFontawesome /> },

];

const publicRoutes = [
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/crypto-ico-landing", component: <CryptoIcoLanding /> },
  { path: "/team", component: <OurTeam /> },
  { path: "/faqs", component: <BqETHFAQ /> },
  { path: "/contact", component: <Contact /> },

  { path: "/learn", component: <Learn /> },
  { path: "/privacy", component: <Privacy/> },
  { path: "/ccpa", component: <CCPA/> },
  { path: "/eula", component: <EULA/> },
  { path: "/pca", component: <PCA/> },
  { path: "/tos", component: <ToS/> },
  { path: "/tpp", component: <Professionals/> },
  { path: "/webinars", component: <Webinars/> },
  { path: "/", exact: true, component: <CryptoIcoLanding /> },  // Default Page

  { path: "/bqeth-pricing", component: <BqETHPricing /> },
]
export { authProtectedRoutes, publicRoutes };
