import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody, CardTitle, Form, Label, Input, FormFeedback, InputGroup } from "reactstrap";
import ClientCaptcha from 'react-client-captcha';

//Import Breadcrumb
import Breadcrumbs from "../../../../Components/Common/Breadcrumb";
import StaticNavbar from "../Navbar/StaticNavBar";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { completeFormThunk } from "slices/bqeth/thunk";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
const getCurrentDate= () => {
  return new Date().toISOString();
}

const FormSupport = () => {

  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch<any>();
  const [cookies, ] = useCookies(['userUuid']);
  const [userCaptchaInput, setUserCaptchaInput] = useState("");
  const [captchaCode, setCaptchaCode] = useState('');

  const formik: any = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      type: "",
      question: "",
      tpporigin: false
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("This field is required"),
      lastname: Yup.string().required("This field is required"),
      email: Yup.string().required("This field is required"),
      type: Yup.string().required("One choice is required"),
      question: Yup.string().required("This field is required"),
    }),

    onSubmit: (values: any) => {
      console.log(JSON.stringify(values, null, 2));
      if (userCaptchaInput === captchaCode) {
        setSubmitted(true); // Close it up
        dispatch(completeFormThunk({ 
          user_hash: cookies['userUuid'], 
          date: getCurrentDate(),
          form: 2,
          email: values.email,
          response: values
        }));
      }
      else {
          alert('Captcha Does Not Match');
          setUserCaptchaInput("");
      }
    },
  });

  const types = ["General Inquiry", "Setting up Hourglass", "Currently Active Hourglass", "Expired/Cancelled Hourglass"];

  const handleChange = (e) => {
    const { checked, name } = e.target;
    if (checked) {
      formik.setFieldValue("type", name);
    } else {
      formik.setFieldValue("type", "")
    }
  };

  return (
    <React.Fragment>
            <div className="modal-body">
              <h5>Support Request</h5>
              <Card>
                <CardBody>
                  {submitted ? <div>Submitted. Thank you.</div>: 
                  <Form className="row gy-2 gx-3 align-items-center" onSubmit={formik.handleSubmit}>
                  <Row className="mb-4" >
                    <Label className="form-check-label" htmlFor="autoSizingFNameInput">First Name</Label>
                    <Col sm={9}>
                    <Input type="text" name="firstname" className="form-control" id="autoSizingFNameInput" placeholder="Jane"
                      value={formik.values.firstname}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      invalid={
                        formik.touched.firstname && formik.errors.firstname ? true : false
                      } />
                    {
                      formik.errors.firstname && formik.touched.firstname ? (
                        <FormFeedback type="invalid">{formik.errors.firstname}</FormFeedback>
                      ) : null
                    }                    
                    </Col>
                  </Row>
                  <Row className="mb-4" >
                    <Label className="form-check-label" htmlFor="autoSizingLNameInput">Last Name</Label>
                    <Col sm={9}>
                    <Input type="text" name="lastname" className="form-control" id="autoSizingLNameInput" placeholder="Doe"
                      value={formik.values.lastname}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      invalid={
                        formik.touched.lastname && formik.errors.lastname ? true : false
                      } />
                    {
                      formik.errors.lastname && formik.touched.lastname ? (
                        <FormFeedback type="invalid">{formik.errors.lastname}</FormFeedback>
                      ) : null
                    }                    
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Label className="form-check-label" htmlFor="autoSizingEmailInput">Your Email</Label>
                    <Col sm={9}>
                    <InputGroup>
                      <div className="input-group-text">@</div>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="autoSizingEmailInput"
                        placeholder="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur} />
                    </InputGroup>
                    {
                      formik.errors.email && formik.touched.email ? (
                        <span className="text-danger">{formik.errors.email}</span>
                      ) : null
                    }
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Label className="form-check-label" htmlFor="autoSizingInputGroup">Your Phone</Label>
                    <Col sm={9}>
                    <InputGroup>
                      <div className="input-group-text">#</div>
                      <input
                        type="tel"
                        name="phone"
                        className="form-control"
                        id="autoSizingInputGroup"
                        placeholder="phone"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur} />
                    </InputGroup>
                    {
                      formik.errors.phone && formik.touched.phone ? (
                        <span className="text-danger">{formik.errors.phone}</span>
                      ) : null
                    }
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <label className="form-check-label" >Type of Question</label>
                    {types.map((type) => (
                      <div key={type}>
                        <input className="form-check-input"
                          id={type}
                          type="checkbox"
                          name={type}
                          checked={formik.values.type == type}
                          onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor={type}>{type}</label>
                      </div>
                    ))}
                    {
                      formik.errors.type && formik.touched.type ? (
                        <span className="text-danger">{formik.errors.type}</span>
                      ) : null
                    }
                  </Row>
                  <Row className="mb-4">
                    <label className="form-check-label" >Your Question</label>
                      <textarea
                        name="question"
                        className="form-control"
                        id="autoSizingQuestion"
                        placeholder="question"
                        value={formik.values.question}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur} />
                    {
                      formik.errors.question && formik.touched.question ? (
                        <span className="text-danger">{formik.errors.question}</span>
                      ) : null
                    }
                  </Row>
                  <Row className="mb-4" >
                      <div className="col mt-3">
                        <ClientCaptcha backgroundColor="#F2F2F2" fontSize="18" fontFamily="DejaVu Sans Mono" charsCount='5' captchaCode={setCaptchaCode} />
                      </div>
                      <div className="col mt-3">
                          <div>
                              <p>Attention: captcha is case sensitive.</p> 
                              <input 
                                    placeholder="Enter Captcha Value" 
                                    id="user_captcha_input" 
                                    name="user_captcha_input" 
                                    value={userCaptchaInput} 
                                    type="text"
                                    onChange={(event) => {
                                        setUserCaptchaInput(event.target.value)
                                      }}
                                    />
                            </div>
                      </div>
                  </Row>

                  <div className="col-sm-auto">
                    <button type="submit" className="btn btn-primary w-md"
                    >Submit</button>
                  </div>
                </Form> 
                }
                </CardBody>
              </Card>
            </div>
    </React.Fragment>
  )
}
export default FormSupport
