import React, { useEffect, useState } from "react";
import "./App.css";
import { authProtectedRoutes, publicRoutes } from "./Routes/allRoutes";
import { Route, Routes } from "react-router-dom";
import VerticalLayout from "./Layouts/VerticalLayout";
import HorizotanlLayout from "./Layouts/HorizotanlLayout";
import "./assets/scss/theme.scss";
import NonAuthLayout from "./Layouts/NonLayout";
import { useCookies } from 'react-cookie';
import CryptoJS from 'crypto-js';

//constants
import {
  LAYOUT_TYPES,
} from "./Components/constants/layout";

import { useDispatch, useSelector } from "react-redux";
import { createSelector } from 'reselect';
import AuthProtected from "Routes/AuthProtected";
import { AppContext, NULLKEY } from "AppContext";
import { v4 as uuidv4 } from 'uuid';
import { newBqETHUserThunk, visitBqETHUserThunk } from "slices/thunk";
import { Button, Col, Input, InputGroup, Modal, ModalBody, ModalHeader, Row } from "reactstrap";


const getLayout = (layoutType: any) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case LAYOUT_TYPES.VERTICAL:
      Layout = VerticalLayout;
      break;
    case LAYOUT_TYPES.HORIZONTAL:
      Layout = HorizotanlLayout;
      break;
    default:
      break;
  }
  return Layout;
};



function App() {

  // Layout stuff
  const selectLeadData = createSelector(
    (state: any) => state.Layout,
    (layoutTypes) => layoutTypes
  );
  const { layoutTypes } = useSelector(selectLeadData);
  const Layout = getLayout(layoutTypes);

  // Management of the userUuid workflow
  const [ rolled, setRolled ] = useState<boolean>(false);
  const [ userUuid, setUserUuid ] = useState<string>(NULLKEY);
  const [cookies, setCookie] = useCookies(['userUuid']);
  const dispatch = useDispatch<any>();
  const [subscribemodal, setSubscribemodal] = useState<boolean>(false);
  
  // Comment out the Subscribe Modal for now.
  // useEffect(() => {
  //   setTimeout(() => {
  //     setSubscribemodal(true);
  //   }, 5000);
  // }, []);

  // <Prompt
  //     when={shouldBlockNavigation}
  //     message='You have unsaved changes, are you sure you want to leave?'
  //   />
  //   {/* Component JSX */}


  // componentDidUpdate = () => {
  //   if (shouldBlockNavigation) {
  //     window.onbeforeunload = () => true
  //   } else {
  //     window.onbeforeunload = undefined
  //   }
  // }

//   const beforeUnloadListener = (event) => {
//     //Send something to back end
//     fetch('uri')
// };

// window.addEventListener("beforeunload", beforeUnloadListener);


  useEffect((): void => {

    // If we have a userUuid cookie, but it's not in our App Context
    if (cookies['userUuid'] && userUuid === NULLKEY) {
      setUserUuid(cookies['userUuid']);
      console.log("Reading Cookie:", cookies['userUuid']);
      // New Visit
      const visit = new Date();
      const v = `${visit.getFullYear()}-${visit.getMonth() + 1}-${visit.getDate()}`;
      var hash = cookies['userUuid'];
      var nv = v.toString();
      dispatch(visitBqETHUserThunk({ user_hash: cookies['userUuid'], new_visit: nv}));
    }
    // If we don't have a cookie, and a userUuid exist in the App Context, generate one and set the cookie
    if (rolled && !cookies['userUuid'] && userUuid !== NULLKEY) {
      var expiryDate = new Date();
      expiryDate.setMonth(expiryDate.getMonth() + 1);
      setCookie('userUuid', userUuid, { path: '/',  expires: expiryDate});
      console.log("Setting Cookie:", cookies['userUuid']);
      // Create new User
      const visit = new Date().toISOString();
      dispatch(newBqETHUserThunk({ user_hash: userUuid, first_visit: visit}));
    }
    if (!rolled && !cookies['userUuid'] && userUuid === NULLKEY) {
      let uuid = uuidv4();
      // Salted hash of the uuid
      let sh = CryptoJS.SHA256('BqETHSalt'+uuid)
      setUserUuid(uuid+"|"+sh);
      setRolled(true);  // Debounce
      console.log("Rolling cookie: ", cookies['userUuid'], " UUID: ", uuid);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolled, cookies, userUuid]);
  

  return (
    <AppContext.Provider value={{ userUuid, setUserUuid }}>
    
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route path={route.path} key={idx}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>} />
        ))}
        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            key={idx}
            element={
              <React.Fragment>
                <AuthProtected>
                  <Layout>{route.component}</Layout>
                </AuthProtected>
              </React.Fragment>
            }
          />
        ))}
      </Routes>
      {/* subscribe ModalHeader */}
      {/* <Modal isOpen={subscribemodal && userUuid != undefined} autoFocus={true} centered toggle={() => { setSubscribemodal(!subscribemodal) }}>
        <div>
          <ModalHeader className="border-bottom-0" toggle={() => { setSubscribemodal(!subscribemodal) }} />
        </div>
        <ModalBody>
          <div className="text-center mb-4">
            <div className="avatar-md mx-auto mb-4">
              <div className="avatar-title bg-light  rounded-circle text-primary h1">
                <i className="mdi mdi-information"></i>
              </div>
            </div>

            <Row className="justify-content-center">
              <Col xl={10}>
                <h4 className="text-primary">Subscribe !</h4>
                <p className="text-muted font-size-14 mb-4">
                  Subscribe to our newletter and get notification to stay updated.
                </p>

                <InputGroup className="rounded bg-light">
                  <Input type="email" className="bg-transparent border-0" placeholder="Enter Email address" />
                  <Button color="primary" type="button" id="button-addon2"> <i className="bx bxs-paper-plane"></i> </Button>
                </InputGroup>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal > */}

    </React.Fragment>
    </AppContext.Provider>
  );
}

export default App;
