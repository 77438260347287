import React, { useState } from "react"
import { Container, Row, Nav, NavItem, NavLink, TabContent, TabPane, Col, CardBody, Card } from "reactstrap";

import StaticNavbar from "../Navbar/StaticNavBar";
import classnames from 'classnames'; 
import FormSupport from "../Forms/FormSupport";
import FormInvestors from "../Forms/FormInvestors";
import BqETHFooter from "../Footer/footer";

const Contact = () => {

 //meta title
document.title = "Contact Us | BqETH";

  // State for current active Tab 
  const [currentActiveTab, setCurrentActiveTab] = useState('Support'); 

  // Toggle active state for Tab 
  const toggle = tab => { 
      if (currentActiveTab !== tab) setCurrentActiveTab(tab); 
  } 

  return (
    <React.Fragment>
      <StaticNavbar imglight={false}/>
      <section className="section" id="contact">
        <Container fluid >
        <h4>For assistance, fill out the support form</h4> 
        <Nav tabs className="justify-content-center"> 
            <NavItem> 
                <NavLink 
                    className={classnames({ active: currentActiveTab === 'Support'})} 
                    onClick={() => { toggle('Support'); }} > 
                    For Users 
                </NavLink> 
            </NavItem> 
            <NavItem> 
                <NavLink 
                    className={classnames({ active: currentActiveTab === 'Investors'})} 
                    onClick={() => { toggle('Investors'); }} > 
                    For Investors 
                </NavLink> 
            </NavItem> 
        </Nav> 
        <TabContent activeTab={currentActiveTab} className="justify-content-center"> 
            <TabPane tabId='Support'> 
              <Row className="justify-content-center">
                <Col lg={8} md={4} sm={2}>
                  <Card style={{width:"60%"}}>
                    <CardBody>
                      <FormSupport />
                    </CardBody>
                  </Card>
                </Col>
              </Row> 
            </TabPane> 
            <TabPane tabId='Investors'> 
              <Row className="justify-content-center">
                <Col lg={8} md={4} sm={2}>
                    <Card style={{width:"60%"}}>
                      <CardBody>
                        <FormInvestors />
                      </CardBody>
                    </Card>
                  </Col>
              </Row>
            </TabPane> 
        </TabContent> 
          
        </Container>
      </section>
      <BqETHFooter />
    </React.Fragment>
  )
}
export default Contact
