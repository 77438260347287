import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody, NavLink, CardTitle, CardHeader, Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import { Link } from "react-router-dom";

//Images
import threshold from "../../../../assets/images/clients/threshold.svg";

//swiper
import "../../../../../node_modules/swiper/swiper.scss";
import { DAPP } from "helpers/url_helper";
import FormButtonComponent from "../RenderComponents/FormComponent";

const AboutUs = () => {

  return (
    <React.Fragment>
      <section className="section bg-white" id="about">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="small-title">About us</div>
                <h4>Why BqETH ?</h4>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg="5">
              <div className="text-muted">
                <h4>The only crypto solution to the crypto problem of Digital Asset Inheritance</h4>
                <p>
                  Everything else can fail. The house can burn down. Your friends will forget their password.
                  Your family will get greedy. The dog will eat the trezor. 
                  The future cannot betray you. 
                </p>
                <p className="mb-4">
                  We created BqETH because all other solutions are a step back into trust, centralization, 
                  and loss of privacy. 
                  We could not compromise.
                  BqETH is a Dead Man Switch that allows you to secure a secret into the future. 
                  Nothing can be leaked. No threats can prevent it. Not even us. 
                </p>

                {/* <div className="d-flex flex-wrap gap-2">
                  <Link to="/learn" className="btn btn-bqeth-teal">Read More</Link>
                  <button className="btn btn-light"><NavLink href="#intro" >How it works</NavLink></button>
                </div> */}
              </div>
            </Col>

            <Col lg="6" className="ms-auto">
              <Card className="border plan-box" outline color="primary" style={{width:"90%"}}>
                <CardHeader  tag="h3">Get Started</CardHeader>
                <CardBody className="p-4">
                    <Row style={{ paddingLeft: "20px", marginTop: "5px" }}>Learn How to use BqETH</Row>
                    <Row style={{ paddingLeft: "20px", marginTop: "5px" }}>
                      <Link to="/learn" className="text-primary">Self-paced course</Link>
                    </Row>
                    <Row style={{ paddingLeft: "20px", marginTop: "5px" }}>
                      <Link to="/faqs" className="text-primary">FAQs</Link>
                    </Row>
                    <Row style={{ paddingLeft: "20px", marginTop: "5px" }}>Launch the App</Row>
                    <div style={{ paddingLeft: "20px", marginTop: "5px" }}>
                      <a href={`${DAPP}?test_mode=true`} target="_blank" rel="noreferrer">Launch App</a>
                    </div>
                    <Row style={{ paddingLeft: "20px", marginTop: "5px" }}>Get Professional Assistance</Row>
                    <div style={{ paddingLeft: "20px", marginTop: "5px" }}>
                      <FormButtonComponent key="F0" content="[F0]" text="Have us find you a Professional Estate advisor" />
                    </div>
                    <div style={{ paddingLeft: "20px", marginTop: "5px" }}>
                      <FormButtonComponent key="F3" content="[F3]" text="Have  us contact your current Estate Advisor" />
                    </div>
                    <div style={{ paddingLeft: "20px", marginTop: "5px" }}>
                      <a href="https://booking.bqeth.com" target="_blank" rel="noreferrer">Schedule a paid consulting session</a>
                    </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <hr className="my-5" />Our Partners
          <Row className="justify-content-center">
            <div className="client-images">
                      <img src={threshold} alt="client-img" className="mx-auto img-fluid d-block" />
                    </div>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default AboutUs;
